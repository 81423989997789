.skill-card {
  display: flex;
  height: 5vw;
  transition: background-color 1s;
  justify-content: center;
  overflow-y: hidden;
  transition: transform 3s;
}

.skill-card-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skill-pic {
  height: 2vw;
}

.skill-collage {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  /* background-color: #422d2d6b; */
  box-shadow: 0px 3px 5px 0px black;
  padding: 2vw;
  height: auto;
  width: 25vw;
  color: white;
}

@media screen and (max-width: 750px) {
  .skill-pic {
    width: 5vw;
    height: 5vw;
  }
}

@media screen and (max-width: 500px) {
  .skill-collage {
    width: 80vw;
    padding: 4vw;
  }

  .skill-card {
    height: 10vw;
  }
}
