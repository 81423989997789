html {
  /* this color might be good for background of cards */
  /* background-color: #422d2d6b; */
  scroll-behavior: smooth;
}
.draw-svg {
  stroke-dasharray: 450;
  stroke-dashoffset: 450;
  animation: draw 2s linear forwards;
}

svg {
  width: 80vw;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

.section-title-description-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 85vw;
  margin-left: 5vw;
  margin-right: 5vw;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.column {
  display: flex;
  flex-direction: column;
}

.all-skills-wrapper {
  gap: 5vw;
}

.skills-content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 40vw;
  transition: width 0.5s;
}

.skills-content:hover .more-skill-content {
  display: flex;
  flex-direction: row;
  opacity: 100%;
  animation-name: fadeIn;
  animation-duration: 2s;
  flex-wrap: wrap;
}

.more-skill-content {
  display: flex;
  flex-direction: row;
  opacity: 100%;
  animation-name: fadeIn;
  animation-duration: 2s;
  flex-wrap: wrap;
  margin: 2vw;
  gap: 1vw;
}

.skills-wrapper {
  /* background: linear-gradient(180deg, hsla(4, 94%, 67%, 1) 0%, hsla(29, 86%, 52%, 1) 100%); */
  border-radius: 12px;
  border: solid white;
  /* background-color: rgba(80, 90, 80, 90%); */
  background: linear-gradient(
    30deg,
    hsla(133, 62%, 55%, 1) 0%,
    hsla(196, 93%, 47%, 1) 100%
  );
}

.home-main {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* background-color: orangered; */
  background-color: white;
  /* gap: 5vw; */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.logo {
  width: 40vw;
  height: auto;
  display: flex;
  animation-name: bounce;
  animation-duration: 2s;
}

.intro-wrapper {
  /* animation-name: floatAround;
  animation-iteration-count: infinite;
  animation-duration: 30s; */
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homepage-nav {
  width: 100vw;
  height: 100dvh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* margin-top: 2vw; */
  border: none;
  flex-wrap: wrap;
  background-color: #ff4500;
}

.subtitle {
  display: flex;
  flex-direction: row;
  /* align-self: baseline; */
  /* width: 45vw; */
  color: black;
}

.down-button {
  border-radius: 50%;
  border: none;
  box-shadow: 0px 3px 2px 0px;
  background-color: #44d362;
  cursor: pointer;
  width: 3vw;
  height: 3vw;
  font-size: 14px;
  transition: all 0.3s;
}

.down-button:hover {
  box-shadow: 0px 5px 20px 0px;
  transform: translateY(-3px);
}

.gap {
  gap: 2vw;
}

.title {
  display: flex;
  /* justify-content: flex-start;
    align-self: flex-start; */
  /* width: 80vw; */
  font-family: Alba;
  /* font-size: calc(6px + 2.25vw); */
  /* text-shadow: 0px 0px 10px grey; */
  /* color: #44D362; */
  color: white;
  kerning: 5px;
  letter-spacing: 2px;
}

.about-me-main {
  display: flex;
  flex-direction: column;
  /* background-color: rgb(80, 80, 80); */
  width: 90vw;
  align-items: center;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  color: white;
}

.about-me-button {
  /* height: calc(4px + 4vh); */
  padding: 6px;
  background: none;
  border-radius: 4px;
  /* border: 0.15vw solid #44D362; */
  border: 3px solid var(--burntOrange);
  cursor: pointer;
  /* color: #44D362; */
  color: var(--burntOrange);
  font-size: 1rem;
  transition: color white;
  transition-duration: 0.5s;
  place-items: center;
  font-weight: bolder;
  transition: all 0.5s;
}

.resume {
  /* background-color: #44D362; */
  /* color: rgb(66, 45, 45); */
  background-color: var(--burntOrange);
  color: white;
}

.about-me-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.about-me-content3 {
  display: flex;
  flex-direction: row;
  width: 80vw;
  justify-content: flex-start;
  align-items: center;
  gap: 3vw;
}

.about-me-content2 {
  display: flex;
  flex-direction: column;
  width: 30vw;
  justify-content: center;
  align-items: center;
}

.input-box {
  border-radius: 6px;
  border: 3px solid;
  /* border-color: var(--burntOrange); */
  /* border-color: #44d362; */
  border-color: black;
  width: 30vw;
  height: 5vh;
  padding: 5px;
}

.message {
  border-radius: 6px;
  border: 3px solid black;
  width: 30vw;
  height: 5vw;
  padding: 5px;
}

.circle {
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  /* background: linear-gradient(180deg, hsla(4, 94%, 67%, 1) 0%, hsla(29, 86%, 52%, 1) 100%); */
  background-image: linear-gradient(0deg, #08aeea 0%, #2af598 100%);
  text-align: center;
}

.divider-line {
  border-radius: 6px;
  width: 80vw;
  height: 0.5vw;
  background-color: white;
}

.about-me-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* background-color: rgb(80, 80, 80); */
  /* background-color: #422d2d6b; */
  /* box-shadow: -6px 6px 0px 0px black;
  border: 3px solid #08aeea;
  border-radius: 12px;
  border-top-right-radius: 75px;
  border-bottom-left-radius: 75px; */
  gap: 5vh;
  width: 80vw;
  padding: 2vw;
  padding-bottom: 4vw;
  padding-top: 4vw;
  margin: 0 auto;
}

#about-me-text {
  text-align: center;
  color: black;
  width: 60%;
  line-height: 1.5rem;
}

.focusSection {
  box-shadow: -6px 6px 0px 0px black;
  border: 3px solid #08aeea;
  border-radius: 12px;
  border-top-right-radius: 75px;
  border-bottom-left-radius: 75px;
  /* color: "black"; 
  justify-content: "center"; 
  width: "100%"; 
  gap: "10vw" */
}

.floatingBubbles {
  width: 50px;
  border: 5px solid white;
  height: 70px;
  border-top-left-radius: 1000px;
  border-top-right-radius: 1000px;
  border-bottom-right-radius: 500px;
  border-bottom-left-radius: 500px;
}

.floatingBubblesContainer div {
  animation-name: float;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}

.floatingBubblesContainer div:nth-child(1) {
  animation-duration: 20s;
  border-color: #08aeea;
}
.floatingBubblesContainer div:nth-child(2) {
  animation-duration: 16s;
  border-color: green;
}
.floatingBubblesContainer div:nth-child(3) {
  animation-duration: 18s;
  border-color: hotpink;
}
.floatingBubblesContainer div:nth-child(4) {
  animation-duration: 11s;
  border-color: yellow;
}

.titles {
  /* color: #44D362; */
  color: var(--burntOrange);
  font-family: alba;
  letter-spacing: 2px;
  font-size: 48px;
  text-align: center;
  width: "100%";
  display: inline-block;
  margin-bottom: 10px;
}

.contactForm {
  display: flex;
  flex-direction: column;
  place-items: center;
  width: 100vw;
  color: white;
  /* background-color: rgb(80, 80, 80); */
  padding-bottom: 1vw;
}

.color {
  background-color: #422d2d6b;
}

.aboutMePicture {
  width: 22.5vw;
  height: auto;
  border-radius: 12px;
}

.submit-button {
  height: calc(4px + 4vh);
  background: none;
  border-radius: 4px;
  /* border: 0.15vw solid #44d362; */
  cursor: pointer;
  /* color: #44d362; */
  border: 3px solid white;
  color: white;
  font-size: 1rem;
  transition: all;
  transition-duration: 0.5s;
  place-items: center;
  font-weight: bolder;
  /* transition: background-color 0.5s, border-color 0.5s; */
}

.submit-button:hover {
  transform: translateY(-2px);
  /* box-shadow: -3px 3px 5px 0px white; */
  box-shadow: -3px 3px 0px 0px black;
}

.about-me-button:hover {
  transform: translateY(-2px);
  /* box-shadow: -3px 3px 5px 0px white; */
  box-shadow: -3px 3px 0px 0px black;
}

@media screen and (max-width: 750px) {
  .about-me-button {
    height: 5vw;
  }
}

@media screen and (max-width: 500px) {
  .default-page {
    margin-left: 0;
    margin-right: 0;
  }
  .about-me-main {
    width: auto;
  }

  .intro-wrapper {
    animation-name: none;
  }

  .floatingBubblesContainer div {
    animation-name: none;
  }

  .section-title-description-wrapper {
    width: auto;
  }

  #about-me-text {
    width: auto;
  }

  .aboutMePicture {
    border-radius: 50%;
  }

  .about-me-section {
    flex-direction: column;
  }

  .down-button {
    width: 10vw;
    height: 10vw;
  }

  /* .input-box {
        width: 90vw;
        padding: 10px;
    } */

  .message {
    width: 90vw;
    height: 10vh;
  }

  .input-box {
    width: 90vw;
  }

  .homepage-nav {
    /* width: 95vw; */
    flex-direction: column-reverse;
  }

  .gap {
    justify-content: center;
  }

  .column {
    width: 100vw;
    justify-content: center;
  }

  /* .column #landing {
    justify-content: center;
  } */

  .logo {
    width: 60vw;
  }

  .intro-wrapper {
    width: 85vw;
    display: flex;
    flex-direction: column;
  }

  .title {
    width: auto;
    font-size: 2.5rem;
    text-align: center;
  }

  .subtitle {
    width: auto;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0%;
  }

  100% {
    opacity: 100%;
  }
}

@keyframes fly-in {
  0% {
    transform: translateX(-100vw);
  }

  100% {
    transform: translateX(0vw);
  }
}

@keyframes floatAround {
  0% {
  }

  25% {
    transform: translateX(100px);
    transform: translateY(80px);
    rotate: -15deg;
  }

  50% {
    transform: translateX(0px);
    transform: translateY(0px);
    rotate: 0deg;
  }

  75% {
    transform: translateX(-100px);
    transform: translateY(80px);
    rotate: 15deg;
  }

  100% {
    transform: translateX(0px);
    transform: translateY(0px);
    rotate: 0deg;
  }
}

@keyframes bounce {
  0% {
    transform: translateX(-15vw);
  }

  25% {
    transform: translateX(-15vw);
  }

  50% {
    transform: scale(1);
    transform: translateX(-15vw);
  }

  100% {
  }
}

@keyframes float {
  0% {
    transform: translateY(0vw);
  }

  50% {
    transform: translateY(-45vw);
    width: 30px;
    border-bottom-left-radius: 300px;
    border-bottom-right-radius: 80px;
    border-top-right-radius: 600px;
    border-top-left-radius: 1200px;
  }

  75% {
    width: 100px;
    border-bottom-left-radius: 1250px;
    border-bottom-right-radius: 300px;
    border-top-right-radius: 800px;
    border-top-left-radius: 60px;
  }

  100% {
    transform: translateY(0vw);
  }
}
