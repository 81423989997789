:root {
  --orangeColor: #ff9400;
  --mainGreenColor: #44d362;
  --mainBlueColor: #08aeea;
  --otherMainBlue: #0483ea;
  --finalBlue: #4f9cdc;
  --burntOrange: #ff6f00;
}

.default-page {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  /* background-color: rgb(43, 77, 109); */
  /* background-color: white; */
  background-color: rgba(255, 148, 0, 0.25);
  margin-left: 10vw;
  margin-right: 10vw;
}
