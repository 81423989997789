.navbar {
  animation-name: showNavBar;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 10;
  /* border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px; */
  border-bottom: 0.25vw solid black;
  gap: 1.5vw;
  width: 100dvw;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  height: 10dvh;
  /* background-color: #422d2d6b; */
  background-color: white;
  /* -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px); */
}

.nav-content {
  width: 95vw;
}

.nav-logo {
  width: 7vw;
  height: auto;
  justify-self: flex-start;
}

@media screen and (max-width: 600px) {
  .navbar {
    height: 7.5vh;
    gap: 7.5vw;
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
  }
}

@keyframes showNavBar {
  0% {
    transform: translateY(-20vh);
  }

  100% {
    transform: translateY(0vh);
  }
}
