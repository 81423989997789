.project-div {
  padding: 2vw;
  display: flex;
  flex-direction: row;
}

a {
  text-decoration: none;
}

.persepctive-item {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: start;
  /* background-color: #08aeea; */
  /* backdrop-filter: blur(20px); */
  background-color: white;
  box-shadow: -6px 5px 0px 0px black;
  border: 3px var(--burntOrange) solid;
  padding: 1vw;
  border-radius: 12px;
  gap: 2vw;
  width: 30vw;
  overflow: auto;
  flex-wrap: wrap;
  padding: 2vw;
  transition: border-color 0.5s, transform 0.5s, background-color 0.5s,
    box-shadow 0.5s, border-width 0.5s;
}

.portfolio-pic {
  width: 100%;
  height: 16vw;
  border-radius: 6px;
  transition: transform 0.5s;
  object-fit: cover;
}

.picture-div {
  display: flex;
  justify-content: center;
}

.project-name {
  font-size: 24px;
  letter-spacing: 2px;
  /* text-shadow: 0px 0px 10px grey; */
  /* color: #44D362; */
  color: var(--burntOrange);
  font-family: alba;
}

.project-description {
  font-size: calc(10px + 0.6vh);
  color: black;
  line-height: 1.5rem;
}

.skills-used {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2vw;
}
.nav-button {
  height: calc(4px + 4vh);
  background: none;
  border-radius: 4px;
  /* border: 0.15vw solid #44D362; */
  border: 3px solid var(--burntOrange);
  cursor: pointer;
  /* color: #44D362; */
  color: var(--burntOrange);
  font-size: 1rem;
  transition: color white;
  transition-duration: 0.5s;
  place-items: center;
  font-weight: bolder;
  transition: all 0.5s;
}

.live-site-button {
  background: none;
  /* padding: 10px; */
  padding: 5px;
  border-radius: 4px;
  border: 3px solid var(--burntOrange);
  cursor: pointer;
  /* color: #44D362; */
  color: var(--burntOrange);
  font-size: 1rem;
  transition: color white;
  transition-duration: 0.5s;
  place-items: center;
  font-weight: bolder;
  transition: all 0.5s;
  text-decoration: none;
}

.source-code-button {
  color: #08aeea;
  border-color: #08aeea;
  transition: all 0.5s;
}

.live-site-button:hover {
  transform: translateY(-2px);
  box-shadow: -3px 3px 0px 0px black;
}

@media screen and (max-width: 750px) {
  .live-site-button {
    font-size: 0.9rem;
    /* height: 5vw; */
  }
}

@media screen and (max-width: 500px) {
  .persepctive-item {
    flex-direction: column;
    width: auto;
    height: auto;
    box-shadow: none;
  }

  .project-description {
    width: 90vw;
  }

  .live-site-button {
    font-size: 0.9rem;
    /* height: 5vw; */
  }

  .portfolio-pic {
    width: 20vw;
    height: 20vw;
  }
}
