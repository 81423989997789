:root {
  --blueColor: #3fdb92;
  --burntOrange: #ff6f00;
  --blueBackgroundColor: hsl(201, 74%, 47%);
  --otherBlueBackgroundColor: rgb(2, 100, 112);
}

.nav-button {
  height: calc(4px + 4vh);
  background: none;
  border-radius: 4px;
  /* border: 0.15vw solid #44D362; */
  border: 3px solid var(--burntOrange);
  cursor: pointer;
  /* color: #44D362; */
  color: var(--burntOrange);
  font-size: 1rem;
  transition: color white;
  transition-duration: 0.5s;
  place-items: center;
  font-weight: bolder;
  transition: all 0.5s;
}

/* .nav-button:active {
  background-color: #ff6f00;
} */

@media screen and (max-width: 600) {
  .nav-button {
    display: none;
  }
}

.nav-button:hover {
  transform: translateY(-2px);
  /* box-shadow: -3px 3px 5px 0px white; */
  box-shadow: -3px 3px 0px 0px black;
}
